import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as LZString from 'lz-string';
@Component({
  selector: 'app-image-download-view',
  templateUrl: './image-download-view.component.html',
  styleUrls: ['./image-download-view.component.scss']
})
export class ImageDownloadViewComponent implements OnInit {
  path: any;
  @ViewChild('pdfView') pdfView: ElementRef;


  constructor()
  { 
    // this.path=localStorage.getItem('viewFile');
    this.path=LZString.decompress(localStorage.getItem('viewFile'))
    console.log('this.path', this.path);
    
    if(this.returnFileType()=='data:application'){
      setTimeout(async () => {
        var obj =this.pdfView.nativeElement;
         if (obj != null) {
          obj.data = this.path;
         }
      }, 100);
    }
  }

  ngOnInit(): void {
  }
  returnFileType() {
    if (this.path != null) {
      if (this.path == undefined) {
        return null;
      } else {
        return this.path.substring(0, this.path.indexOf('/'));
      }
    } else {
      return null;
    }
  }
  downloadImg(event){
    var a = document.createElement("a"); //Create <a>
    a.href = this.path; //Image Base64 Goes here
    a.download = "Image.png"; //File name Here
    a.click(); //Downloaded file
  }

  downloadPdf(event){
    var a = document.createElement("a"); //Create <a>
    a.href = this.path; //Image Base64 Goes here
    a.download = "file.pdf"; //File name Here
    a.click(); //Downloaded file
  }
}
