import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../core/services/http.service';
import { StaticAPI } from '../shared/constants/static-api';

@Injectable({
  providedIn: 'root'
})
export class GetPaystubMetaDataGuard implements Resolve<any> {
  constructor(private httpService: HttpService){

  }

  /**
   *
   * @returns
   */
   getApiData(trn_id):Observable<any>{
    const getDataBody = {
      trn_id: trn_id,
      document_id :3
    };
    return this.httpService.POST_DATA(StaticAPI.getPaystubMetaData.url, getDataBody);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getApiData(route.params.param5);
  }
}
