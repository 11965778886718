import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../core/services/http.service';
import { StaticAPI } from '../shared/constants/static-api';
import { metaDataAPIResponseType } from '../shared/interface';

@Injectable({
  providedIn: 'root'
})
export class AdminInfoGuard implements Resolve<metaDataAPIResponseType> {
  constructor(private httpService: HttpService) {
  }

  /**
  * This function is used to get applicant details
  * @returns Observable<MetaDataResponse|null>
  */
  getAdminDetails(trn_id): Observable<metaDataAPIResponseType> {
    return this.httpService.GET_DATA(StaticAPI.getAdminData.url.replace('@@TRN_ID@@', trn_id));
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getAdminDetails(route.params.param5);
  }

}
