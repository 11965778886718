import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {
  private messageSource = new BehaviorSubject<urlObject>({url:null,state:null});
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: urlObject) {
     this.messageSource.next(message)
  }
}
export interface urlObject{
  url:string,
  state:boolean
}
