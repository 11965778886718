export const StaticAPI = {
    // login_API = 'http://www.json-generator.com/api/json/get/bVmhfmxOdK?indent=2'
    login: {
        type: 'POST',
        url: '/api/user/login'
    },
    paystubReprocess:{
        type: "POST",
        url: 'upload/paystub-reprocess'
    },
    getSuperUser: {
        type: 'GET',
        url: `application/superuser/${Date.now()/1000}`
    },
    proofOfIdentitySave:{
        type:"POST",
        url:'upload/proof-of-identity/save'
    },
    docUploadProofOfIdentity: {
        type: 'POST',
        url: 'upload/proof-of-identity'
    },
    docUploadUserData: {
        type: 'POST',
        url: 'upload/uploadBackBarcode'
    },
    docUploadProofOfIdentityNoDoc: {
        type: 'POST',
        url: 'upload/proof-of-identity/save'
    },
    verifyOTP:{
        type:'POST',
        url:'upload/verifyOTP'
    },
    docUploadProofOfSnn: {
        type: 'POST',
        url: 'upload/proof-of-ssn'
    },
    docUploadProofOfSnnNoDoc: {
        type: 'POST',
        url: 'upload/proof-of-ssn/save'
    },
    docUploadOtherIncome: {
        type: 'POST',
        url: 'upload/other-income'
    },
    docUploadBankingInformation: {
        type: 'POST',
        url: 'upload/banking-section'
    },
    docUploadOthers: {
        type: 'POST',
        url: 'upload/other-documnet'
    },
    saveProofOfSNN: {
        type: 'POST',
        url: 'upload/proof-of-ssn/save'
    },
    saveProofOfIncome: {
        type: 'POST',
        url: 'upload/SaveProofOfIncome'
    },
    // saveOtherIncomeDocument: {
    //     type: 'POST',
    //     url: 'upload/reviewSubmitOtherIncomeDetails'
    // },
    saveOtherIncomeDocument: {
        type: 'POST',
        url: 'upload/other-income-save'
    },
    // saveBankingInformationDocument: {
    //     type: 'POST',
    //     url: 'upload/reviewSubmitBankingDetails'
    // },
    saveBankingInformationDocument: {
        type: 'POST',
        url: 'upload/banking-section-save'
    },
    applicantionSataus:{
        type:'POST',
        url:'upload/updateApplicationStatusForApplicant'
    },
    docUploadPage: {
        type: 'POST',
        url: 'upload/doc-upload-page'
    },
    uploadProofOfIncome: {
        type: 'POST',
        url: 'upload/proof-of-income-admin'
    },
    uploadProofOfIncomeInPerticularEmployer: {
        type: 'POST',
        url: 'upload/proof-of-income'
    },

    getProofOfIncome: {
        type: 'GET',
        url: 'upload/getPaystubDocDetails/@@TRN_ID@@'
    },
    getProofOfIncomeAdmin: {
        type: 'GET',
        url: 'upload/getPaystubDetailsForAdmin/@@TRN_ID@@'
    },
    getApplicantData: {
        type: 'GET',
        url: 'upload/applicant-details/@@TRN_ID@@'
    },
    getAdminData: {
      type: 'GET',
      url: 'upload/getSubscriberDetails/@@TRN_ID@@'
    },
    getOtherIncomeInfo: {
        type: 'GET',
        url: 'upload/GetOtherIncomeDocument/@@TRN_ID@@'
    },
    getOtherDocumentInfo: {
        type: 'GET',
        url: 'upload/GetOtherDocument/@@TRN_ID@@'
    },
    getBankingInfo: {
        type: 'GET',
        url: 'upload/GetBankDocument/@@TRN_ID@@'
    },
    getQueryGenerationReport: {
        type: 'GET',
        url: '/api/analytics/getQueryGenerationReport?channel=@@CHANNEL@@&interval=@@INTERVAL@@&filterDate=@@FILTER_DATE@@',
    },
    setUserStatus: {
        type: 'PUT',
        url: '/api/user/setUserStatus',
    },
    getPaystubMetaData: {
        type: 'POST',
        url: 'upload/getMetadata'
    },
    deleteDocument: {
        type: 'POST',
        url: 'upload/deleteApplicationDocument'
    },
    deleteSource:{
      type:'POST',
      url:'upload/deleteSource'
    },
    changeVerificationStatus: {
        type: 'POST',
        url: 'upload/proof-of-income'
    },
    getDetailsRefresh:{
        type:'GET',
        url:'upload/getDetailsRefresh/@@TRN_ID@@'
    },
    isOtpVerify:{
        type:'GET',
        url:'upload/isOtpVerify/@@TRN_ID@@'
    },
    saveNoUploadDocumentReason:{
        type:'POST',
        url:'upload/saveNoUploadDocumentReason'
    },
    saveAdminStatus:{
        type:'POST',
        url: 'upload/saveVerificationStatus'
    },
    updateApplicationStatusForAdmin:{
        type:'POST',
        url:'upload/updateApplicationStatusForAdmin'
    },
    saveSOCRComment:{
        type:'POST',
        url:'upload/saveSOCRComment'
    },
    GenerateOtp:{
      type:'POST',
      url:'upload/GenerateOtp'
    },
    updateValidationMessages:{
        type:'POST',
        url:'upload/updateValidationMessages'
    },
    ML_paystub_list:{
        type:'POST',
        url:'upload/GetMLclassDetails'
    },
    addSoruce: {
        type: 'POST',
        url: 'upload/addSource'
    }
};



// THIS IS HOW GET API CALL
// getSupervisorList_API(allUsersByRole: RAllUsersByRole | string, supervisorId: number): Observable<RGroup[]> {
//     return this.httpService.GET_DATA<RGroup[]>(
//       StaticAPI.getAllUsersByRole.url
//         // tslint:disable-next-line: no-string-literal
//         .replace('@@USER_NAME@@', allUsersByRole.hasOwnProperty('name') ? allUsersByRole['name'] : allUsersByRole)
//         .replace('@@ACCESS_ID@@', supervisorId ? supervisorId.toString() : '')
//       // StaticAPI.getAllUsersByRole.staticURL
//     );
//   }
