import { Component } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, first, take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'docUpload';
  cameraModule: boolean;
  loading: boolean;
  constructor(private route: ActivatedRoute,private router: Router){
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event:any)=> {
        if (new RegExp('/camera').test(event.url)) {
          this.cameraModule=true;
        }else{
          this.cameraModule=false;
        }
          console.log("this.router.url",event);
      });

      this.router.events.subscribe((event: any) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading = true;
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading = false;
            break;
          }
          default: {
            break;
          }
        }
      });

  }


}
