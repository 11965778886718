<div class="verfied-wrap" style="width: 100vw;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="ver-mob-block">
                    <div class="mob-body success-body">
                        <div class="success-block text-center">
                            <h5 class="mr-b20">
                                Thank you for uploading documents!
                            </h5>
                            <!-- <p>
                                Upload other documents now. <br> Eg. Proof of Income, Proof of SSN, Banking
                                or
                                Other Documents
                            </p> -->
                            <div class="done-icon mr-t60">
                                <svg width="122" height="125" viewBox="0 0 122 125" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="61" cy="61.8823" r="61" fill="#DBF4FB" />
                                    <path d="M44.3833 66.0683L56.9154 78.6004L77.8022 49.3589"
                                        stroke="#4BAA94" stroke-width="5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle cx="61.0964" cy="63.9793" r="58.4036" stroke="#4BAA94"
                                        stroke-width="5" />
                                </svg>
                            </div>
                            <p class="mt-4"><strong>If you want to revisit doc upload for uploading documents, then use the link sent on invitation email</strong></p>
                        </div>
                    </div>
                    <!-- <div class="mob-foot">
                        <div class="row align-items-center">
                            <div class="col-sm-12 text-right">
                                <button type="button" class="blue-btn btn">Continue</button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
