import { Component, OnInit } from '@angular/core';
import { LoadingScreenService } from './../../core/services/loading-screen.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalStoreService } from 'src/app/global-store.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  loading: any = {loading:false,requestUrl:''};
  loadingSubscription: Subscription;
  loadingArr=[];
  constructor(
    private loadingScreenService: LoadingScreenService,
    private globalService:GlobalStoreService
  ) { }

  ngOnInit(): void {
    this.globalService.currentMessage.subscribe(m => {
   // console.log("🚀 ~ file: loading-screen.component.ts ~ line 24 ~ LoadingScreenComponent ~ ngOnInit ~ m", m)
          if (m.state == true)
            this.loadingArr.push(1);
          else if (m.state == false)
            this.loadingArr.pop()
      });
    // this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(
    //   debounceTime(200)
    // ).subscribe((value) => {
    //   this.loading = value;

    //   // console.log("this.loading :", this.loading)

    //   if (this.loading) {
    //     // this.blockUI.start('Loading...');
    //   } else {
    //     // this.blockUI.stop();
    //   }

    // });

  }

  ngOnDestroy() {
   // this.loadingSubscription.unsubscribe();
  }
}
