<header class="header-wrap">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <div class="logo-block">
                    <img src="assets/images/w-logo.png" alt="docuverus" title="docuverus" />
                </div>
            </div>
        </div>
    </div>
</header>