<div style="position: fixed;right: 0px;top: 2%;z-index: 22222; " class="alert alert-warning" *ngIf="loadingArr.length>0">
  <h4 class="alert-heading small">Please wait, loading the request..</h4>
  <!-- <small>Loading the request...</small> -->
    <!-- <div class="card" style="width: 18rem;">
      <div class="card" style="width: 18rem;">
        <div class="card-body">

          <div class="d-flex align-items-center">

            <small>Please wait your documents are still processing...</small>
          </div>
        </div>
      </div>
      </div> -->
</div>
