import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { NavbarComponent } from './subcomponent/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { LoadingScreenComponent } from './subcomponent/loading-screen/loading-screen.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SimpleModalModule } from 'ngx-simple-modal';
import { ConfirmComponent } from './subcomponent/confirm/confirm.component';
import { ImageViewerModule } from '@emazv72/ngx-imageviewer';
import { ImageDownloadViewComponent } from './subcomponent/image-download-view/image-download-view.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoadingScreenComponent,
    ConfirmComponent,
    ImageDownloadViewComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule, // ToastrModule added
    ButtonsModule.forRoot(),
    PdfViewerModule,
    AlertModule.forRoot(),
    SimpleModalModule.forRoot({container: "modal-container"}),
    ImageViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
