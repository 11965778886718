
<div *ngIf="loading" style="
position: fixed;
background-color: #000000d9;
width: 100%;
height: 100vh;
z-index: 1;
">
<div class="loader"></div>


</div>
<main class="body-main-wrap" *ngIf="!cameraModule">
    <app-loading-screen></app-loading-screen>
    <app-navbar></app-navbar>
    <section class="flex-routing">
        <router-outlet></router-outlet>
    </section>
</main>

<main  class="body-main-wrap" *ngIf="cameraModule">
    <app-loading-screen></app-loading-screen>
    <router-outlet></router-outlet>
</main>
<!-- Modal -->
<div class="modal fade" id="infoModal" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
    aria-hidden="true">
    <div class="modal-dialog applicant-info-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content applicant-content">
            <div class="modal-header">
                <h5 class="modal-title">Applicant Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-in-table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Value
                                </th>
                                <th>
                                    Update By
                                </th>
                                <th>
                                    Timestamp
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    681 Arbor Lane
                                </td>
                                <td>System</td>
                                <td>10/08/2020 24:20:20</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
