
// import { NotificationService } from './../services/notification.service';
// import { LoggingService } from './../services/logging.service';
// import { ErrorService } from './../services/error.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

@Injectable()
export class GlobalErrorInterceptor implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error) {
    // const errorService = this.injector.get(ErrorService);
    // const logger = this.injector.get(LoggingService);
    // const notifier = this.injector.get(NotificationService);
    if (error instanceof Error) {
      if (!navigator.onLine) {
        // notifier.showError('No Internet Connection', true);
      }
      // logger.logError(error.message, error.stack);
      console.error(error);

    }
  }
}
