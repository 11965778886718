import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { pluck, repeat, retry } from 'rxjs/operators';
import { Observable, forkJoin, interval, combineLatest } from 'rxjs';
import { defer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  POST_DATA<T>(apiURL: string, postObject: any): Observable<T> {
    return this.http.post<T>(apiURL, postObject).pipe(pluck('records'));
  }

  PUT_DATA<T>(apiURL: string, postObject: any): Observable<T> {
    return this.http.put<T>(apiURL, postObject).pipe(pluck('records'));
  }
  GET_DATA<T>(apiURL: string): Observable<T> {
    return this.http.get<T>(`${apiURL}`).pipe(pluck('records'));
  }
  DELETE_DATA<T>(apiURL: string): Observable<T> {
    return this.http.delete<T>(`${apiURL}`).pipe(pluck('records'));
  }
}
