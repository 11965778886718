// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:5025/api/',
  mlapiUrl: 'https://pentest-ats-api.dvapply.com/ml/flagPaystub',
  apiUrl: 'https://pentest-docupload-api.dvapply.com/api/',
  // apiUrl: 'https://uatdocuploadapi.dvapply.com/api/',
  // mlapiUrl: 'https://uatapiml.dvapply.com/ml/flagPaystub',
  formatAcceptForUpload:"image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
};

/* 
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
