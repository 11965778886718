import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../core/services/http.service';
import { StaticAPI } from '../shared/constants/static-api';
import { OtherIncomeApiResponseType } from '../shared/interface';

@Injectable({
  providedIn: 'root'
})
export class OtherIncomeGuard  implements Resolve<OtherIncomeApiResponseType> {
  constructor(private httpService: HttpService){}

   /**
   *
   * @returns
   */
    getOtherIncome(trn_id):Observable<OtherIncomeApiResponseType>{
      const getDataBody = {
        trn_id: trn_id,
      };
      return this.httpService.GET_DATA(StaticAPI.getOtherIncomeInfo.url.replace('@@TRN_ID@@',trn_id));

    }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getOtherIncome(route.params.param5);
  }
}
