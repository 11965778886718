import { Injectable, Injector, Inject } from '@angular/core';
import { environment } from './../../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiHeaderInterceptor implements HttpInterceptor {

  // THIS IS FOR SKIPP URL WHICH DONT NEED TOKEN
  skippUrls = [
    'https://api.randomuser.me/',
    environment.mlapiUrl,
  ];
  location: any;
  location1: string;
  constructor(private inj: Injector,private router: Router) {
    //console.log(">>>>>>>>>>>>>>>>>>",window.location.pathname.split("/").toString());
    this.location=window.location.pathname.split("/");
    this.location1=this.location[2]+"/"+this.location[3]+"/"+this.location[4]+"/"+this.location[5]+"/"+this.location[6];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (!request.url.includes('assets/')) {
      const token = this.getToken();
      if (this.OwnApplicationAPIMechanisam(request)) {
        if (token) {
          request = request.clone({
            setHeaders: {
              'trn_id':this.location[6],
              'x-auth-token': token,
              "timeout": '500000',
            }, url: environment.apiUrl + request.url,
          });
        } else {
          request = request.clone({ url: environment.apiUrl + request.url });
        }
      }
      else {
        request = request.clone({ url: request.url });
      }
    // }
    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
         return;
        }
        var url_string =window.location.href;
        var url = new URL(url_string);
        var c = url.searchParams.get("token");
       // localStorage.setItem('UserID',c);
       sessionStorage.setItem('redirectBack',window.location.pathname);
       this.router.navigate(["otp/"+this.location1]); //{queryParams:{UserID:c}}
      }
    }));
  }
  OwnApplicationAPIMechanisam = (request): boolean => {
    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(request.url)) {
        return false;
        break;
      }
    }
    return true;
  }

  getToken() {
    // const cryptoJSService = this.inj.get(CryptoJSService);
    // if (sessionStorage.getItem('token') && sessionStorage.getItem('token').length > 0) {
    //   return cryptoJSService.DecryptData(sessionStorage.getItem('token')).replace(/["']/g, '');
    // }
    var url_string =window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("token");
    return  sessionStorage.getItem('token')||c;
    //return sessionStorage.getItem('token');
  }
}
