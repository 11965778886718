import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, finalize, map } from 'rxjs/operators';
import { LoadingScreenService } from './../services/loading-screen.service';
import { GlobalStoreService } from 'src/app/global-store.service';
import { EMPTY } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from 'src/app/subcomponent/confirm/confirm.component';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

  activeRequests = 0;

  /**
   * URLs for which the loading screen should not be enabled
   */
  // skippUrls = [
  //   '/api/upload/proof-of-income',
  //   'api/upload/proof-of-identity',
  //   'api/upload/proof-of-ssn',
  //   'api/upload/doc-upload-page',
  //   'api/upload/other-income',
  //   'api/upload/banking-section',
  //   'upload/other-documnet'
  //   // StaticAPI.getQueryStatsGridData.url,
  //   // StaticAPI.setReferenceNo.url
  // ];


  constructor(
    // private loadingScreenService: LoadingScreenService,
    private globalService: GlobalStoreService,
    private SimpleModalService: SimpleModalService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.globalService.changeMessage({ url: request.url, state: true });


    //return next.handle(request).pipe();
    // return next.handle(request);
    //let displayLoadingScreen = true;

    // for (const skippUrl of this.skippUrls) {
    //  if (new RegExp(skippUrl).test(request.url)) {
    //     displayLoadingScreen = false;
    //     break;
    //   }
    // }

    //if (displayLoadingScreen) {
    ///if (this.activeRequests === 0) {
    //this.loadingScreenService.startLoading(request.url);

    // }
    // this.activeRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        //  this.activeRequests--;
        // if (this.activeRequests === 0) {
        // this.loadingScreenService.stopLoading();
        this.globalService.changeMessage({ url: request.url, state: false });
        // }
      })
    );
  }
  //else {
  // return next.handle(request);
  // }
  // }

}
