import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from '../core/services/http.service';
import { StaticAPI } from '../shared/constants/static-api';
import { superuserResponceType } from '../shared/interface';

@Injectable({ providedIn: 'root' })
export class SuperUserGuard implements Resolve<superuserResponceType> {

  constructor(private httpService: HttpService) { }

  getApiData():Observable<any> 
  {
    return this.httpService.GET_DATA(StaticAPI.getSuperUser.url);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  {
    return this.getApiData();
  }
}
