import { ApiHeaderInterceptor } from './api-header.interceptor';
import { HttpCalcelInterceptor } from './http-calcel.interceptor';
import { GlobalErrorInterceptor } from './global-error.interceptor';
import { ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './loading-screen.interceptor';
import { DeleteAPIInterceptor } from './delete-api.interceptor';
import { ErrorInterceptor } from './error.interceptor';

export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiHeaderInterceptor,
        multi: true
    },
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: ApiErrorHandlerInterceptor,
    //     multi: true
    // },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpCalcelInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingScreenInterceptor,
        multi: true
    },
    // {
    //     provide:HTTP_INTERCEPTORS,
    //     useClass:DeleteAPIInterceptor,
    //     multi:true
    // },
    {
        provide: ErrorHandler,
        useClass: GlobalErrorInterceptor,
    },
    {
        provide:HTTP_INTERCEPTORS,
        useClass:ErrorInterceptor,
        multi:true
    }
];
