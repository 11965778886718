import { AdminInfoGuard } from './guard/admin-info.guard';
import { ApplicantDataGuard } from './guard/applicant-data.guard';
import { SuperUserGuard } from './guard/application-super-user.guard';
import { DocUploadPageGuard } from './guard/doc-upload-page.guard';
import { GetBankMetaDataGuard } from './guard/get-bank-meta-data.guard';
import { GetBankingSectionDataGuard } from './guard/get-banking-section-data.guard';
import { GetOtherSectionDataGuard } from './guard/get-other-section-data.guard';
import { GetPaystubForAdminGuard } from './guard/get-paystub-for-admin.guard';
import { GetPaystubMetaDataGuard } from './guard/get-paystub-meta-data.guard';
import { GetRefreshAPIGuard } from './guard/get-refresh-api.guard';
import { IsOtpVerifyGuard } from './guard/is-otp-verify.guard';
import { OtherIncomeGuard } from './guard/other-income.guard';
import { OtpGenerateGuard } from './guard/otp-generate.guard';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { ImageDownloadViewComponent } from './subcomponent/image-download-view/image-download-view.component';
import { SuccessComponent } from './subcomponent/success/success.component';
export const routes = [
  {
    path: 'camera',
    loadChildren: () =>
      import('./modules/mobile-upload/mobile-upload.module').then(
        (m) => m.MobileUploadModule
      ),
  },
  {
    path: 'admin/:param1/:param2/:param3/:param4/:param5',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    resolve: {
      applicantDetail: ApplicantDataGuard,
      docuploadPage: DocUploadPageGuard,
      otherIncome: OtherIncomeGuard,
      getOther: GetOtherSectionDataGuard,
      getBank: GetBankingSectionDataGuard,
      getPaystub: GetPaystubForAdminGuard,
      getPaystubMetaAdmin: GetPaystubMetaDataGuard,
      getBankMetaDataGuard: GetBankMetaDataGuard,
      getAdminData: AdminInfoGuard,
      getSuperUser: SuperUserGuard
    },
  },
  {
    path: 'docupload/:param1/:param2/:param3/:param4/:param5',
    loadChildren: () =>
      import('./modules/doc-upload-form/doc-upload-form.module').then(
        (m) => m.DocUploadFormModule
      ),
    resolve: {
      applicantDetail: ApplicantDataGuard,
      refreshData: GetRefreshAPIGuard,
      getPaystubMetaAdmin: GetPaystubMetaDataGuard,
      getBankMetaDataGuard: GetBankMetaDataGuard,
      getAdminData: AdminInfoGuard,
      docuploadPage: DocUploadPageGuard,
    },
  },
  {
    path: 'otp/:param1/:param2/:param3/:param4/:param5',
    loadChildren: () =>
      import('./modules/otp/otp.module').then((m) => m.OtpModule),
    resolve: {
      // OtpGenerateGuard,
    },
  },
  { path: 'success', component: SuccessComponent },
  { path: 'view/:path', component: ImageDownloadViewComponent },
  { path: '**', component: PageNotFoundComponent },
];
