import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpService } from '../core/services/http.service';
import { StaticAPI } from 'src/app/shared/constants/static-api';

@Injectable({
  providedIn: 'root'
})
export class GetOtherSectionDataGuard implements Resolve<any> {
  constructor(private httpService: HttpService){
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return   this.httpService
    .GET_DATA(
      StaticAPI.getOtherDocumentInfo.url.replace(
        '@@TRN_ID@@',
        route.params.param5
      )
    );
  }

}
