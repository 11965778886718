import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve, ActivatedRoute } from '@angular/router';
import { empty, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../core/services/http.service';
import { StaticAPI } from '../shared/constants/static-api';
import { proofOfIdsAndSSNApiResponseType } from '../shared/interface';

@Injectable({
  providedIn: 'root'
})
export class DocUploadPageGuard implements Resolve<proofOfIdsAndSSNApiResponseType> {
  constructor(private httpService: HttpService){

  }

  /**
   *
   * @returns
   */
   getProofOfIdsAndSSN(trn_id):Observable<proofOfIdsAndSSNApiResponseType>{
    const getDataBody = {
      trn_id: trn_id,
    };
    return this.httpService.POST_DATA(StaticAPI.docUploadPage.url, getDataBody);

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getProofOfIdsAndSSN(route.params.param5);
  }

}
