// import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { HttpService } from './services/http.service';
// import { SharedModule } from './../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { httpInterceptorProviders } from './interceptors/index';
import { HttpCancelService } from './services/http-cancel.service';
import { LoadingScreenService } from './services/loading-screen.service';
import { ToastService } from './services/toast.service';
// import { AuthenticationService } from './services/authentication.service';
// import { AuthGaurd } from './gaurds/authentication.guard';


import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  providers: [
    // AuthGaurd,
    httpInterceptorProviders,
    // AuthenticationService,
    LoadingScreenService,
    HttpCancelService,
    HttpService,
    ToastService
  ],
  imports: [
    // CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    // SharedModule,
    HttpClientModule
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    // SharedModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
