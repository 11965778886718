import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, tap } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private toastService: ToastService,) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen(errors => errors.pipe(
        tap(() => {
          console.log("retrying...");
        }),
        // Use concat map to keep the errors in order and make sure they
        // aren't executed in parallel
        concatMap((e, i) =>
          // Executes a conditional Observable depending on the result
          // of the first argument
          iif(
            () => i > 1,
            // If the condition is true we throw the error (the last error)
            throwError(e),
            // Otherwise we pipe this back into our stream and delay the retry
            of(e).pipe(delay(500))
          )
        )
      )
        // catchError((error: HttpErrorResponse) => {

        //   let errorMsg = '';
        //   if (error.error instanceof ErrorEvent) {
        //     console.log('this is client side error');
        //     errorMsg = `Error: ${error.error.message}`;
        //     this.toastService.showError(errorMsg);
        //   }
        //   else {
        //     console.log('this is server side error');
        //     errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        //     this.toastService.showError(errorMsg);
        //   }
        //   console.log(errorMsg);
        //   return throwError(errorMsg);
        // })
    ))
  }
}
//return Observable.empty<HttpEvent<any>>();
