import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeComponent } from './docupload/home/home.component';
// import { VerifyDocUploadComponent } from './docupload/verify-doc-upload/verify-doc-upload.component';
// import { GuestcardComponent } from './guestcard/guestcard/guestcard.component';
// import { IdentityUploadComponent } from './guestcard/identity-upload/identity-upload.component';
// import { SuccessComponent } from './guestcard/success/success.component';
// import { VerifyIdentityUploadComponent } from './guestcard/verify-identity-upload/verify-identity-upload.component';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { ProofOfIdentityComponent } from './subcomponent/proof-of-identity/proof-of-identity.component';

// const routes: Routes = [
//   {
//     path: '',
//     component: PageNotFoundComponent,
//   },
//   {
//     path: 'docupload/:param1/:param2/:param3/:param4/:param5',
//     component: HomeComponent,
//   },
//   {
//     path: 'docuploadverify/:param1/:param2/:param3/:param4/:param5',
//     component: VerifyDocUploadComponent,
//   },
//   {
//     path: 'guestcard/:param1/:param2/:param3/:param4/:param5',
//     component: GuestcardComponent,
//   },
//   {
//     path: 'guestcardverify/:param1/:param2/:param3/:param4/:param5',
//     component: VerifyIdentityUploadComponent,
//   },
//   { path: 'success', component: SuccessComponent },
//   { path: '**', component: PageNotFoundComponent },
// ];

import { routes } from "./route";
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
